<template>
  <v-row dense>
    <v-col>
      <v-card>
        <v-card-text>
          <v-row dense>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-dialog
                ref="dialog_from_date"
                v-model="modal_from_date"
                :return-value.sync="trx_model.from_date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="trx_model.from_date"
                    label="From Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    @change="dateChangeHandler"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="trx_model.from_date"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="modal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog_from_date.save(trx_model.from_date);dateChangeHandler(trx_model.from_date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-dialog
                ref="dialog_to_date"
                v-model="modal_to_date"
                :return-value.sync="trx_model.to_date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="trx_model.to_date"
                    label="To Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    @change="dateChangeHandler"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="trx_model.to_date"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="modal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog_to_date.save(trx_model.to_date);dateChangeHandler(trx_model.to_date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="4"
              v-if="from !== 'deposit'"
            >
              <v-autocomplete
                dense
                v-model="trx_model.category_code"
                label="Category"
                :items="categories"
                item-text="name"
                item-value="code"
                clearable
                @change="dateChangeHandler"
              ></v-autocomplete>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="4"
              v-if="$store.getters.currentUser.role.level === 1"
            >
              <v-autocomplete
                dense
                v-model="trx_model.merchant_code"
                label="Agent"
                :items="agents"
                item-text="full_name"
                item-value="bank_code"
                clearable
                @change="agentChangeHandler"
              ></v-autocomplete>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-autocomplete
                  dense
                  v-model="trx_model.user_id"
                  label="Locket"
                  :items="lockets"
                  item-text="full_name"
                  item-value="_id"
                  clearable
                  @change="dateChangeHandler"
              ></v-autocomplete>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="4"
            >
              <v-autocomplete
                dense
                v-model="trx_model.status"
                label="Status"
                :items="status_list"
                clearable
                @change="dateChangeHandler"
              ></v-autocomplete>
            </v-col>
            <template v-if="from !== 'deposit'">
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  class="font-weight-bold"
              >
                Tot Trx : {{ tot_trx | trxFormat }} <br />
                Fee Merchant : {{ fee_agent | amountFormat }}
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  class="font-weight-bold"
              >
                Tot Amount : {{ tot_amt | amountFormat }} <br />
                Fee Kilat : {{ fee_kilat | amountFormat }}
              </v-col>
            </template>
            <template v-else>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  class="font-weight-bold"
              >
                Tot Trx : {{ tot_trx | trxFormat }} <br />
                Tot Amount : {{ tot_amt | amountFormat }}
              </v-col>
            </template>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "TrxSearchFormComponen",
  data: () => ({
    modal_from_date: false,
    modal_to_date: false,
  }),
  props: {
    trx_model: Object,
    categories: [],
    agents: [],
    lockets: [],
    // roles: [],
    // merchants: [],
    // update: String
    fee_agent: Number,
    fee_kilat: Number,
    tot_trx: Number,
    tot_amt: Number,
    status_list: [],
    from: String
  },
  mounted() {

  },
  methods: {
    agentChangeHandler(val) {
      this.action.$emit('change-agent-action', val);
    },
    dateChangeHandler(val) {
      this.action.$emit('change-date-action', val);
    }
  }
}
</script>

<style scoped>

</style>
